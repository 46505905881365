import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Icon,
  Button,
  List,
  ListItem,
  ListIcon,
  IconButton,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { PlanStyle } from "./Plan.style";
import { fetchSubscriptionPlansAction } from "../../Actions/B2CSubscription.action";
import {

  createB2CSubscriptionHandler,
  openRazorpayCheckout,
} from "../../Actions/B2CPayment.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveUser } from "../../../../../Redux/User-slice/User-slice";

declare global {
  interface Window {
    Razorpay: any;
  }
}

export interface SubscriptionDetailsInterface {
  subscription_id: string;
  plan_id: string;
  status:
  | "created"
  | "authenticated"
  | "active"
  | "halted"
  | "cancelled"
  | "completed"; // Assuming typical subscription statuses
  short_url: string;
  user_id: string;
  subscriber_id: string;
}

export interface SubscriptionPlanInterface {
  planName: string;
  description: string;
  planId: string;
  amount: string;
  period: "daily" | "weekly" | "monthly" | "yearly"; // Assuming period can have specific string values
  interval: number;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

const CustomCheckIcon = (props: any) => (
  <Icon viewBox="0 0 19 15" {...props}>
    <path
      width={"50.432px"}
      d="M18.4513 3.97426L14.1353 8.29094L10.035 12.3913L8.72551 13.7008C8.16398 14.2617 7.04212 14.2617 6.48118 13.7008L0.870055 8.08963C0.507449 7.72702 0.379014 7.13019 0.485346 6.60271C0.542694 6.31298 0.67113 6.04363 0.870055 5.8447L2.1795 4.53586C2.73983 3.97432 3.8623 3.97432 4.42383 4.53586L7.60365 7.71561L11.9693 3.34941L13.9418 1.37635L14.8976 0.421148C15.4591 -0.140383 16.5816 -0.140383 17.1419 0.421148L18.4513 1.72999C18.6043 1.88346 18.716 2.07826 18.7853 2.29206C18.9711 2.86196 18.8594 3.56626 18.4513 3.97426Z"
      fill="#2A94F4"
    />
  </Icon>
);

const PlanDetails = ({ setStep }: any) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const userData: any = useSelector((state: any) => state.user);
  const [plans, setPlans] = useState<SubscriptionPlanInterface[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [benCount, setBenCount] = useState<number>(1);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const incrementBenCount = () => {
    setBenCount((prevCount) => prevCount + 1);
  };

  const decreamentBenCount = () => {
    setBenCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await fetchSubscriptionPlansAction();
        setPlans(res);
        setSelectedPlan(res[0]);
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      }
    };
    fetchData();
  }, []);

  const selectPlanHandler = (plan: SubscriptionPlanInterface) => {
    setSelectedPlan(plan);
  };

  const paymentResponseHandler = async (response: any) => {
    dispatch(saveUser({ ...userData, numberOfBenAllowed: benCount }))
    navigate(`/subscription/payment-confirmation/${response.razorpay_subscription_id}/${response.razorpay_payment_id}/${benCount}`)
  };

  const HandlePayment = async () => {
    setIsLoading(true)
    if (!selectedPlan) {
      alert("Please Select Plan");
      return;
    }
    let subscriptionData: SubscriptionDetailsInterface =
      await createB2CSubscriptionHandler({
        planId: selectedPlan.planId,
        quantity: benCount,
      });
    if (!subscriptionData) {
      return;
    }

    const options = {
      key: "rzp_test_I4S0hxzvPnFLDQ",
      name: "Pococare",
      // image: 'https://your-logo-url.com/logo.png',
      subscription_id: `${subscriptionData.subscription_id}`,
      handler: paymentResponseHandler,
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.mobileNo.split('-')[1],
      },
      notes: { order_by: "subscription", ref_id: subscriptionData.subscription_id },
      theme: {
        color: "#F37254",
      },
    };

    openRazorpayCheckout(options);
    setIsLoading(false)
  };

  return (
    <Box mt={"100px"} bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
      height={"100vh"}>
      <Box
        display={"flex"}
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"center"}
        p={5}
        backgroundImage="url('https://developmentbucket.blob.core.windows.net/svgs/image_prev_ui.svg')"
        borderRadius="md"
        width="full"
        mx="auto"
        margin={"auto"}
      >
        {plans.map((val: any) => (
          <Box
            width={isMobile ? "full" : "550px"}
            height={isMobile ? "385px" : "360px"}
            border={"1px solid rgba(0, 0.00, 0.00, 0.50)"}
            bg="white"
            boxShadow="sm"
            p="8"
            onClick={() => selectPlanHandler(val)}
          >
            <Text
              color="#333"
              fontFamily="Poppins"
              fontSize="22px"
              fontWeight="500"
              lineHeight="36px" // This is equivalent to 163.636%
              letterSpacing="1.32px"
              mb="4"
            >
              {val.planName}
            </Text>
            <List spacing={3}>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={"16.136px"}
                  bg="#CCE9FF"
                  width={"16.136px"}
                  color="teal.500"
                />
                <Text fontSize={"12px"} fontWeight={"400px"}>
                  {" "}
                  Fastest response from incident to ER Reliable every time.
                </Text>
              </ListItem>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={"16.136px"}
                  bg="#CCE9FF"
                  width={"16.136px"}
                  color="teal.500"
                />
                <Text fontSize={"12px"} fontWeight={"400px"}>
                  {" "}
                  Immediate assessment by Virtual Doctor.
                </Text>
              </ListItem>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={"16.136px"}
                  bg="#CCE9FF"
                  width={"16.136px"}
                  color="teal.500"
                />
                <Text fontSize={"12px"} fontWeight={"400px"}>
                  {" "}
                  Patients Medical Record's, current condition and virtual
                  doctor's diagnosis sent to hospital ER pre arrival.
                </Text>
              </ListItem>
              <ListItem display={"flex"} flexDir={"row"} alignItems={"center"}>
                <ListIcon
                  as={CustomCheckIcon}
                  height={"16.136px"}
                  bg="#CCE9FF"
                  width={"16.136px"}
                  color="teal.500"
                />
                <Text fontSize={"12px"} fontWeight={"400px"}>
                  {" "}
                  Patient brought to nearest appropriately equipped hospital
                  every time. No diversion to other hospitals.
                </Text>
              </ListItem>
            </List>
            <Box
              mt={6}
              mb={isMobile ? 1 : 2}
              height={"1px"}
              bgColor={"rgba(0, 0, 0, 0.10)"}
            ></Box>
            <Box
              textAlign={"center"}
              alignContent={"center"}
              borderRadius="8px"
              border={"1px solid #00A7B5"}
              mt="5"
              height={"40px"}
              width="full"
              color="#00A7B5"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500px"
              lineHeight="36px"
            >
              Rs.{val.amount}/- per beneficiary
            </Box>
          </Box>
        ))}

        <Box
          p={5}
          mt={isMobile ? 5 : 0}
          ml={isMobile ? "" : "3"}
          bgColor={"white"}
          border={"1px solid rgba(0, 0.00, 0.00, 0.50)"}
          boxShadow="sm"
          w={isMobile ? "full" : "394px"}
          height={isMobile ? "350px" : "360px"}
          style={PlanStyle.chooseNobenStyle}
        >
          <Box mt="4">
            <Text style={PlanStyle.headingStyle}>
              Choose no. of Beneficiary
            </Text>
            <Box
              padding={"7px"}
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23 21.0009V19.0009C22.9993 18.1146 22.7044 17.2536 22.1614 16.5532C21.6184 15.8527 20.8581 15.3524 20 15.1309"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 3.13086C16.8604 3.35116 17.623 3.85156 18.1676 4.55317C18.7122 5.25478 19.0078 6.11769 19.0078 7.00586C19.0078 7.89403 18.7122 8.75694 18.1676 9.45855C17.623 10.1602 16.8604 10.6606 16 10.8809"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Box marginLeft={"15px"} display={"flex"} flexDir={"row"}>
                <IconButton
                  isDisabled={benCount <= 1 ? true : false}
                  onClick={decreamentBenCount}
                  style={PlanStyle.noOfBenInnerStyle}
                  aria-label="Decrease count"
                  icon={<MinusIcon />}
                // mr={2}
                />
                <Box
                  ml={"7px"}
                  mr={"7px"}
                  w="74px"
                  style={PlanStyle.noOfBenInnerStyle}
                >
                  {benCount}
                </Box>
                <IconButton
                  onClick={incrementBenCount}
                  style={PlanStyle.noOfBenInnerStyle}
                  aria-label="Increase count"
                  icon={<AddIcon />}
                />
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <Text style={PlanStyle.headingStyle}> Total Amount</Text>
            <Box
              padding={"7px"}
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
            >
              <Image
                src="https://developmentbucket.blob.core.windows.net/logos/rupee-logo.png"
                alt="Rupee logo"
              />

              <Box
                marginLeft={"15px"}
                borderRadius="4px"
                border="1px solid rgba(51, 51, 51, 0.20)"
                display="inline-flex"
                padding="14px 58px 13px 16px"
                alignItems="center"
              >
                {(benCount * Number(selectedPlan?.amount)) / 100}
              </Box>
            </Box>
          </Box>
          <Box mt={"46px"} height={"1px"} bgColor={"rgba(0, 0, 0, 0.10)"}></Box>{" "}
          <Box mt={"10px"} mb={"8px"}>
            <Button
              background="#00A7B5"
              width={"full"}
              color="#FFF"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="22.53px"
              onClick={HandlePayment}
              isLoading={isLoading}
            >
              Pay Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanDetails;
