import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, CircularProgressLabel, Text } from "@chakra-ui/react";
import style from '../Styles/checkStatus.module.css'
import { checkB2CPaymentStatusHandler } from "../../../Actions/B2CPayment.action";
import { PaymentStatusStages } from "../PaymentConfirmation";

interface paymentResponseInterface {
    razorpay_subscription_id: string,
    razorpay_payment_id: string,
    ben_count: number,
    setStage: any,
    setResponseData: any
}

const PaymentStatus = {
    created: "created",
    authorized: "authorized",
    captured: "captured",
    refunded: "refunded",
    failed: "failed",
};

export const CheckStatus: React.FC<paymentResponseInterface> = ({ setResponseData, setStage, ben_count, razorpay_payment_id, razorpay_subscription_id }) => {
    const navigate = useNavigate()
    const [time, setTime] = useState<number>(60)

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTime((prev) => {
                if (prev <= 1) {
                    // Clear the interval when time reaches 0
                    clearInterval(timerInterval);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timerInterval);
    }, [])

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                let res = await checkB2CPaymentStatusHandler({
                    payment_id: razorpay_payment_id,
                    subscription_id: razorpay_subscription_id,
                });

                if (res.status === PaymentStatus.captured) {
                    setResponseData(res)
                    setStage(PaymentStatusStages.PaymentSuccess)
                } else if (res.status === PaymentStatus.failed) {
                    setResponseData(res)
                    setStage(PaymentStatusStages.PaymentFailed)
                } else if (res.status === PaymentStatus.refunded) {
                    setResponseData(res)
                    setStage(PaymentStatusStages.PaymentFailed)
                }
            } catch (error: any) {
                console.error("Error checking payment status:", error);
                if (error?.response?.status === 401) {
                    navigate('/dashboard')
                }
                // alert("An error occurred while checking the payment status.");
            }
        };

        if (ben_count && razorpay_payment_id && razorpay_subscription_id) {
            checkPaymentStatus();
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (time === 0) {
            let responsePayloadForFailedScreen = {
                order_id: razorpay_payment_id,
                timestamp: Math.floor(new Date().getTime() / 1000)
            }
            setResponseData(responsePayloadForFailedScreen)
            setStage(PaymentStatusStages.PaymentFailed)
        }
           // eslint-disable-next-line
    }, [time])

    return (
        <Box className={style.main}>
            <Text className={style.basicText}>
                Please wait for few more seconds for the payment to get completed.
            </Text>
            <Box className={style.timerBox}>
                <CircularProgress isIndeterminate
                    color='#786ACD'
                    size='100%'
                    thickness='2px'
                    alignItems={"center"}
                    justifyContent={"center"}

                    className={style.timerDisplay}
                >
                    <CircularProgressLabel fontSize={"150%"}>
                        <Box >{formatTime(time)}</Box>
                        {/* <Box className={style.timeleftText} >Time Left</Box> */}
                    </CircularProgressLabel>
                </CircularProgress>
            </Box>
            <Text className={style.basicText}>
                Do not close or refresh
            </Text>
        </Box>
    )
}