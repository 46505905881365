import { Box, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

const TxtComponent = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  return (
    <Box  >
      {/* Left side with logo and description */}
      <Box flex="1" p={isMobile ? "4px":  "8px"}>
        {!isMobile && (
          <Image
            width={"60%"}
            src="https://developmentbucket.blob.core.windows.net/logos/poco-care-text-logo.png"
            alt="PocoCare Logo"
            mb="4"
          />  
        )}
        <Text
          color="#333"
          fontSize={isMobile ? "14px":"16px"}
          fontFamily="Poppins"
          fontWeight="500px"
          lineHeight="26px"
          letterSpacing="0.72px"
          fontStyle="normal"
          mb="2"
        >
          Medical Emergency
        </Text>
        <Text
          color="#333"
          fontSize={isMobile ? "12.92px":"16px"}
          fontFamily="Poppins"
          fontWeight="500px"
          lineHeight="26px"
          letterSpacing="0.72px"
          fontStyle="normal"
        >
          "Incident to Hospital Admission"
          <br />
          Service Anytime
        </Text>
        <Text
          color="#333"
          fontSize={isMobile ? "8.92px":"12px"}
          fontFamily="Poppins"
          fontWeight="400"
          lineHeight="26px"
          letterSpacing="0.72px"
          fontStyle="normal"
        >
          “Peace of Mind for you and your family”
        </Text>
      </Box>
    </Box>
  );
};

export default TxtComponent;
