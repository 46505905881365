import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState: initialState,
  reducers: {
    saveSubscriber: (state, action) => {
      // console.log('state:from Sub', state)
      // console.log('action:from Sub', action)
      return { ...state, ...action.payload };
    },
    getUser: (state) => {
      return state;
    },
    removeSubscriber: (state) => {
      state = [];
      return state;
    },
  },
});
export const { saveSubscriber, getUser, setSubscriberData, removeSubscriber } =
  subscriberSlice.actions;
export default subscriberSlice.reducer;
