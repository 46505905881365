import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  b2cUserSendOtpAction,
  b2cVerifyOtpAction,
  createB2CUserAction,
} from "../../../Actions/B2CSubscription.action";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
  createBen,
  userRegOtpPayload,
  userRegPayload,
} from "../../../Common/Payloads";
import { createBeneficiariesForB2c } from "../../../../../AddBeneficiaryMinDetails/Action/createBenficiaries";
import { saveSubscriber } from "../../../../../../Redux/Subscriber-Slice/Subscriber-slice";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { saveUser } from "../../../../../../Redux/User-slice/User-slice";
import { getSubscriber } from "../../../../Otp/Action/getSubscriber";
import { saveSbsSubscriberData } from "../../../../../../Redux/B2CSubscriber-Slice/Subscriber-sbs-slice";
import TxtComponent from "../TxtComponent";

const VerifyOtp = ({ setStep }: any) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const userData = useSelector((state: any) => state.subscription);

  const handleSubmitOtp = async () => {
    try {
      setLoading(true);
      const verifyOtp = userRegOtpPayload(userData, otp);
      const verify = await b2cVerifyOtpAction(verifyOtp);

      // After successfully verifying the otp create the USER;
      if (verify.success) {
        // create the users hitting the user create api.....
        const userRgPayload = userRegPayload(userData);

        // After that it will create user + subscriber.. then we have to work on create beneficiary;
        let res = await createB2CUserAction(userRgPayload);
        // GET THE USER AND SUBS DATA HERE .
        let subscriptionData = await getSubscriber(res?.data?.subscriberId);

        dispatch(saveSbsSubscriberData(subscriptionData));

        const benData = createBen(res.data);
        const benRes = await createBeneficiariesForB2c(benData, dispatch);
        dispatch(saveSubscriber(benRes.data[0].response.data));
        // Setting the user data in user Slice..

        const userSlicePayload = {
          userId: res.tokenData.userId,
          name: res.data.fullname,
          email: res.data.email,
          mobileNo: `91-${res.data.mobile}`,
          clientPlatform: res.data.clientPlatform,
          userRole: res.data.userRole,
          addBeneficiaryAllowed: res.data.addBeneficiaryAllowed,
          numberOfBenAllowed: res.data.numberOfBenAllowed,
          subscriberId: res.data.subscriberId,
          beneficiaryId: res.data.sub,
          accessToken: res.tokenData.accessToken,
          refreshToken: res.tokenData.refreshToken,
          statusCode: res.statusCode || null,
          message: res.message,
        };

        dispatch(saveUser(userSlicePayload));

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000); // Stop confetti after 3 seconds

        setLoading(false);
        navigate(`/planDetails?email=${userData?.email}`);
        // setStep("SUBSCRIBE_PLAN");
      }
      return ShowAlert("success", "Successfully verified otp..");
    } catch (error: any) {
      setLoading(false);
      return ShowAlert(
        "warning",
        error?.response?.data?.message || "Error while verifying the OTP..."
      );
    }
  };

  const handleResendOtp = async () => {
    const payload = {
      countryCode: "91",
      email: userData.email,
      number: userData.mobile,
    };
    await b2cUserSendOtpAction(payload);
    return ShowAlert("success", "Otp successfully.");
  };

  return (
    <Box
      bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
      position={"fixed"}
      marginTop={"85px"}
      minH={"full"}
      top={0}
      width={"full"}
    >
      <Flex
        flexDir={isMobile ? "column" : "row"}
        backgroundImage="url('https://developmentbucket.blob.core.windows.net/svgs/image_prev_ui.svg')"
        width={"80%"}
        margin={"auto"}
        justifyContent={isMobile ? "start" : "space-between"}
        alignItems={"center"}
      >
        <TxtComponent />
        <Box>
          {showConfetti && <Confetti />}
          <Box
            marginTop={"50px"}
            margin={"auto"}
            maxW={isMobile ? "370px" : "502px"}
            height={isMobile ? "260.819px" : "400px"}
            bg={"white"}
          >
            <Box
              mt={8}
              width={isMobile ? "320px" : "500px"}
              justifyContent={"center"}
            >
              <Box
                mt={"30px"}
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                textAlign={"center"}
                alignContent={"center"}
              >
                <Box marginTop={isMobile ? "8" : "14"}>
                  <Heading size="xs" fontSize={isMobile ? "12.39px" : "20px"}>
                    Verify OTP
                  </Heading>
                </Box>
                <Box
                  margin={"auto"}
                  marginTop={"14px"}
                  maxW={isMobile ? "172.493px" : "372.493px"}
                >
                  {" "}
                  <Text
                    sx={{
                      color: "#919598",
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: isMobile ? "7.434px" : "14px",
                      fontStyle: "normal",
                      fontWeight: isMobile ? "400" : "500",
                      lineHeight: isMobile ? "12px" : "24px", // 171.429%
                      textTransform: "camelCase",
                    }}
                  >
                    Please enter One time password(OTP) sent to your mobile
                    number (+91 XXXXXX{userData?.mobile?.slice(-4) || "0000"})
                  </Text>
                </Box>
              </Box>
              <Box
                margin={"auto"}
                mt="26px"
                textAlign={"center"}
                borderRadius={"8px"}
              >
                <Box position={"relative"}>
                  <Text
                    position={"absolute"}
                    fontSize={isMobile ? "6.195px" : "10px"}
                    bg="white"
                    top={isMobile ? "-1.5" : "-3"}
                    zIndex={10}
                    left={isMobile ? "45px" : "65px"}
                    width={"40px"}
                  >
                    OTP
                  </Text>
                  <Input
                    maxLength={6} // Enforce the maximum length of 6 digits
                    onChange={(e) => setOtp(e.target.value)}
                    width={isMobile ? "246.744px" : "398.281px"}
                    height={isMobile ? "32px" : "51.213px"}
                    variant="outline"
                    placeholder="* * * * * *"
                  />
                </Box>
              </Box>
              <Flex
                marginLeft={isMobile ? "35px" : "48px"}
                p={"6px"}
                justifyContent={"left"}
              >
                <Text
                  color="#919598"
                  fontFamily="Poppins"
                  fontSize={isMobile ? "8px" : "11px"}
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="16px"
                >
                  Didn’t receive OTP ?{" "}
                </Text>
                <Text
                  cursor={"pointer"}
                  onClick={handleResendOtp}
                  ml={1}
                  color="#407BFF"
                  fontFamily="Poppins"
                  fontSize={isMobile ? "8px" : "11px"}
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="16px"
                >
                  Resend OTP
                </Text>
              </Flex>
              <Box
                margin={"auto"}
                mt={isMobile ? "5px" : "10px"}
                textAlign={"center"}
              >
                <Button
                  onClick={handleSubmitOtp}
                  isLoading={loading}
                  loadingText={"Loading.."}
                  height={isMobile ? "32px" : "51.213px"}
                  width={isMobile ? "246.744px" : "398.281px"}
                  color={"white"}
                  fontSize={isMobile ? "9.912px" : "16px"}
                  borderRadius="8px"
                  background="#00A7B5"
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default VerifyOtp;
