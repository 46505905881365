import { getSubscriberById } from "../../../../http"


export const getSubscriber = async (id: string) => {
    try {
        let response = await getSubscriberById(id)
        return response?.data?.data
    } catch (error) {
        console.log(error)
        throw error
    }
}