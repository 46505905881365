export const formatUnixTimeStamp = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds

    // Arrays for day and month names
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Extract date components
    const dayName = dayNames[date.getDay()];
    const monthName = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0'); // Ensure two digits
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Ensure two digits

    // Return formatted date string
    return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds}`;
}

