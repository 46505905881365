import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
export const subscriberSbsDataSlice = createSlice({
  name: "subscriberSbsData",
  initialState: initialState,
  reducers: {
    saveSbsSubscriberData: (state, action) => {
      console.log(action,'Save sbs subs action...'); 
      return { ...state, ...action.payload };
    },
    getSbsSubsriberData: (state) => {
      return state;
    },
  },
});
export const { saveSbsSubscriberData, } = subscriberSbsDataSlice.actions;
export default subscriberSbsDataSlice.reducer;
