import { Box } from "@chakra-ui/react"
import Navbar from "../../../../../GlobalComponents/Navbar/Navbar"
import style from './Styles/paymentConfirmation.module.css'
import { useEffect, useState } from "react"
import { CheckStatus } from "./Components/CheckStatus"
import { useNavigate, useParams } from "react-router-dom"
import { PaymentSuccess } from "./Components/PaymentSuccess"
import { PaymentFailed } from "./Components/PaymentFailed"


export enum PaymentStatusStages {
    CheckStatus = "CheckStatus",
    PaymentSuccess = "PaymentSuccess",
    PaymentFailed = "PaymentFailed"
}

interface paymentResponseDataInterface {
    status: string;
    amount: string;
    order_id: string;
    timestamp: string;
}

export const SubscriptionPaymentConfirmation = () => {
    const [stage, setStage] = useState<PaymentStatusStages>(PaymentStatusStages.CheckStatus)
    const [paymentResponseData, setPaymentResponseData] = useState<paymentResponseDataInterface>()
    const { subscription_id, payment_id, ben_count } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (isNaN(Number(ben_count))) {
            navigate("/dashboard")
        }
    }, [ben_count, navigate])

    return (
        <Box>
            <Navbar ></Navbar>
            <Box className={style.parent}>
                {(() => {
                    switch (stage) {
                        case PaymentStatusStages.CheckStatus:
                            return (
                                <CheckStatus
                                    setResponseData={setPaymentResponseData}
                                    setStage={setStage}
                                    ben_count={Number(ben_count)}
                                    razorpay_payment_id={payment_id || ""}
                                    razorpay_subscription_id={subscription_id || ""}
                                />);
                        case PaymentStatusStages.PaymentSuccess:
                            return (
                                <PaymentSuccess
                                    amount={paymentResponseData?.amount}
                                    timeStamp={paymentResponseData?.timestamp}
                                    orderId={paymentResponseData?.order_id}
                                    benCount={Number(ben_count)}
                                />)
                        case PaymentStatusStages.PaymentFailed:
                            return (
                                <PaymentFailed
                                    amount={paymentResponseData?.amount}
                                    timeStamp={paymentResponseData?.timestamp}
                                    orderId={paymentResponseData?.order_id}
                                />);
                        default:
                            return <CheckStatus setResponseData={setPaymentResponseData} setStage={setStage} ben_count={Number(ben_count)} razorpay_payment_id={payment_id || ""} razorpay_subscription_id={subscription_id || ""} />;
                    }
                })()}
            </Box>
        </Box>
    )
}

