import {
  b2cUserSendOtp,
  b2cVerifyOtp,
  createB2CUser,
  fetchSubscriptionPlan,
  getSubscriptionById,
} from "../../../../http";

export const b2cUserSendOtpAction = async (payload: any) => {
  try {
    const res = await b2cUserSendOtp(payload);
    return res;
  } catch (error) {
    console.log(error, "Error in b2cUserSendOtpAction");
    throw error;
  }
};

export const b2cVerifyOtpAction = async (payload: any) => {
  try {
    const res = await b2cVerifyOtp(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createB2CUserAction = async (payload: any) => {
  try {
    const res = await createB2CUser(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSubscriptionPlansAction = async () => {
  try {
    const res = await fetchSubscriptionPlan();
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSubscriptionByIdAction = async (subscriptionId: string) => {
  try {
    const res = await getSubscriptionById(subscriptionId);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// fetch plan details.
